<template>
  <div>
    <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
      <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">領獎紀錄</h4>
      <div class="
          col-12 col-xl-6
          d-flex
          flex-xl-row
          align-items-start align-items-xl-center
          justify-content-end
        ">
        <b-button class="flex-shrink-0 mb-0 ml-2 btn mr-3" variant="primary" @click="handleExport" v-if="merchantId">
          <i class="fa fa-plus"></i>匯出 excel
        </b-button>
        <template v-if="showFilter">
          <b-form-select v-model="merchantId" :options="merchants" :disabled="merchants.length == 2" @change="fetchRedeemLogs"></b-form-select>
        </template>
      </div>
    </div>

    <b-table striped hover responsive :items="redeemLogs" :fields="fields" :busy="isLoading" @sort-changed="handleSort">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(customer_name)="data">
        {{ data.item.customer_name | hiddenString(20) }}
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
      </template>
    </b-table>

    <div class="d-flex justify-content-center" style="margin-top: 80px">
      <b-pagination class="separated" v-model="currentPage" :total-rows="total" :per-page="perPage"
        aria-controls="organizationList"></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointLogApi from "@/apis/point-log";
import merchantApi from "@/apis/merchant";
import store from "@/store";
import moment from "moment";

export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    includeFields: {
      type: Array,
      default: () => [],
    },
    upperMerchantId: {
      type: String,
      default: null,
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      search: "",
      redeemLogs: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      sortBy: "created_at",
      orderBy: "desc",
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    fields() {
      let fields = [
        {
          key: "created_at",
          label: "兌換時間",
          sortable: true,
        },
        {
          key: "gift_name",
          label: "獎項名稱",
        },
        {
          key: "gift_data",
          label: "獎項參數",
        },
        {
          key: "batch_total_points",
          label: "使用點數",
        },
        {
          key: "customer_name",
          label: "LINE 名稱",
        },
        {
          key: "customer_profile_name",
          label: "會員姓名",
        },
        {
          key: "customer_mobile_phone",
          label: "手機",
        },
        {
          key: "customer_email",
          label: "Email",
        },
        {
          key: "customer_county",
          label: "縣市",
        },
        {
          key: "customer_district",
          label: "區域",
        },
        {
          key: "customer_address",
          label: "地址",
        },
      ]

      if (this.includeFields.length > 0) {
        fields = fields.filter(field => this.includeFields.includes(field.key))
      }

      return fields
    },
  },
  mounted() {
    this.fetchMerchants();
  },
  watch: {
    currentPage: function (value) {
      this.fetchRedeemLogs(value)
    }
  },
  methods: {
    async fetchRedeemLogs(page) {
      try {
        this.isLoading = true;
        const { data } = await pointLogApi.getRedeemLogs(this.upperMerchantId || this.merchantId, {
          page: page || this.currentPage,
          per_page: this.perPage || 15,
          sort_by: this.sortBy,
          order_by: this.orderBy,
          // 若 upperCustomerId 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
          customer_id: this.upperCustomerId || '',
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.redeemLogs = data.data.map((log) => ({
          ...log,
          customer_profile_name: log.customer_profiles.name,
          customer_county: log.customer_profiles.county,
          customer_district: log.customer_profiles.district,
          customer_address: log.customer_profiles.address,
        }));
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleSearch() {
      if (this.merchantId) {
        this.currentPage = 1;
        this.fetchRedeemLogs();
      }
    },
    handleExport() {
      if (this.merchantId) {
        const searchParams = new URLSearchParams({
          sort_by: this.sortBy,
          order_by: this.orderBy,
          token: store.state.auth.token,
          // 若 upperCustomerId 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
          customer_id: this.upperCustomerId || '',
        });
        const url = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}/merchants/${this.merchantId}/redeem-logs/export?${searchParams}`;

        window.open(url);
      }
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant
      if (data.data.length == 1) {
        defaultMerchant = data.data[0]
      } else {
        defaultMerchant = data.data.filter(merchant => merchant.type == 'LINE').shift()
      }

      if (defaultMerchant) {
        this.merchantId = defaultMerchant.id;
        this.fetchRedeemLogs();
      }
    },
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.fetchRedeemLogs();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
};
</script>

